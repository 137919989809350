// @ts-ignore
import Wedding from "../public/images/about/wedding.jpg";
import Desert from "../public/images/about/desert.jpg";
import Shoes from "../public/images/about/boots-shoes.jpg";
import CiDel from "../public/images/about/ci-del.jpeg";
import KzKessel from "../public/images/about/kz-kessel.jpeg";
import KzOude from "../public/images/about/kz-oudeheverlee.jpeg";
//import DontBeADick from "../public/images/about/dont-be-a-dick.jpg";
//import QuadBike from "../public/images/about/quadbike.jpg";
//import ChristmasInWilderness from "../public/images/about/christmas-wilderness.jpg";
//import Ottertrail from "../public/images/about/ottertrail.jpg";
//import Restaurant from "../public/images/about/restaurant.jpg";
//import Running from "../public/images/about/running.jpg";
//import HikingProfile from "../public/images/about/profile-lionshead.jpg";
//import Aaro from "../public/images/about/resume-profile.jpg";
//import Kirstenbosch from "../public/images/about/kirstenbosch.jpg";
//import Halloween from "../public/images/about/halloween.jpg";
//import AdelphiFamily from "../public/images/about/profile-adelphi.jpg";
//import AlphaBackpack from "../public/images/about/alpha-backpack.jpg";
//import RestaurantDiy from "../public/images/about/restaurant-diy.jpg";
//import AlphaLionshead from "../public/images/about/alpha-mountain.jpg";
//import Camping from "../public/images/about/camping.jpg";
//import GreatIdeas from "../public/images/about/great-ideas.jpg";

export const ABOUT = {
  stats: [
    {
      statistic: `${
        new Date(Date.now() - new Date("1999-09-19T01:30:00").getTime()).getFullYear() - 1970
      }`,
      tooltip: `${Math.round(
        (Date.now() - new Date("1999-09-19T01:30:00").getTime()) / 1000
      )} seconds`,
      caption: "Years Old",
    },
    {
      statistic: "3+",
      tooltip: "First line of code written in 2014",
      caption: "Years of Data Engineering",
    },
    {
      statistic: "3",
      tooltip: "Started motorcycling at the age of 14.",
      caption: "An 1100cc & Two 500cc Motorcycles",
    },
    {
      statistic: "2569",
      tooltip: "111 GitHub Stars",
      caption: "Git Commits",
    },
  ],
  description: (
    <>
      <p>
  I am a dedicated Data Engineer with a passion for technology, splitting my time between the EU and UAE/IN regions. My expertise lies in leveraging Python, Scala, and Apache
  frameworks to design robust data solutions for impactful projects. Outside the realm of engineering,
  I find joy in cruising on my motorcycle, exploring the open road, and engaging in powerlifting,
  channeling a blend of adventure and strength in my life.
</p>
<p>
  Throughout my journey, I have honed my skills in crafting high-performance systems and
  automating complex processes, ensuring seamless integration and optimal functionality.
  I thrive on the challenge of optimizing user experiences and pushing the boundaries of
  what's possible in the data engineering landscape.
</p>
<p>
  My tech-centric journey began with an ardent interest in technology, evolving into a career centred
  around creating exceptional user experiences. Whether it's mastering the intricacies of Python or Scala
  or navigating the terrains on my motorcycle, my pursuit of excellence remains unwavering, mirroring my
  dedication to both innovation and adventure.
</p>
    </>
  ),
  images: [

    {
      src: CiDel,
      alt: "The CI-500.",
    },
    {
      src: KzOude,
      alt: "The KZ in the Oude Heverlee forest.",
    },
    {
      src: Desert,
      alt: "My two beauties in the desert.",
    },
    {
      src: Shoes,
      alt: "Boots & Shoes overseeing the beautiful Namur lake.",
    },
    {
      src: Wedding,
      alt: "Conference dinner w/ a bunch of grandpas.",
    },
    {
      src: KzKessel,
      alt: "My KZ after a wash.",
    },

    /*
    {
      src: DontBeADick,
      alt: "Flying next to colourful hot air balloons in LT, EU.",
    },
    {
      src: QuadBike,
      alt: "Old man and his RE in Amsterdam, NL.",
    },
    {
      src: ChristmasInWilderness,
      alt: "Took her out on a cold rainy night in  DEL, IN.",
    },
    {
      src: Ottertrail,
      alt: "The Catholic Church of LEU, BE.",
    },
    {
      src: Restaurant,
      alt: "Getting out of an Afghani restaurant in CDG, FR.",
    },
    {
      src: Running,
      alt: "Yo!",
    },

    {
      src: Kirstenbosch,
      alt: "Christmas carols in Kirstenbosch, Newlands.",
    },
    {
      src: Halloween,
      alt: "Halloween night.",
    },
    {
      src: HikingProfile,
      alt: "Tiger-head",
    },
    {
      src: RestaurantDiy,
      alt: "DIY upgrading our restaurant, including soundproofing.",
    },
    {
      src: AdelphiFamily,
      alt: "Suited up, VNO, LT.",
    },

    {
      src: AlphaLionshead,
      alt: "Alpha smiling on top of Lions head",
    },
    {
      src: Aaro,
      alt: "Filtered SC pic w/ Aaro.",
    },
    {
      src: Camping,
      alt: "Camping at Addo Elephant national Park.",
    },
    {
      src: GreatIdeas,
      alt: "Reading a new book in a Jacuzzi in Cederkloof, Citrusdal.",
    },
    {
      src: AlphaBackpack,
      alt: "Hiking for 33km over Table mountain and giving Alpha a little rest in the backpack.",
    },
    */
  ],
};
