// @ts-ignore
import heroCode from "!!raw-loader!content/code-blocks/hero.tsx"; // Adding `!!` to a request will disable all loaders specified in the configuration
import { Link } from "components/link";
import { TECH } from "content/tech-stack";
import party from "party-js";
//import HeaderTitle from "components/HeaderTitle";

const textCommonStyle = {
  fontSize: "75px", // Adjust the font size as needed
  lineHeight: "0.9", // Optional: Adjust line height for better spacing
};

export const HERO = {
  pre: "hello, world! ;)",
  heading: (
    <>
      {/* <HeaderTitle /> uncomment for animated title */}
      I'm <strong style={textCommonStyle}>Prabhjyoth Mattummal</strong>, a Data Engineer.
    </>
  ),
  tech: [
    TECH.python,
    TECH.rust,
    TECH.c,
    TECH.scala,
    TECH.cpp,
    TECH.docker,
    TECH.postgresql,
    TECH.git,
    TECH.azure,
    TECH.kubernetes,
    TECH.azureDevOps,
    TECH.nvim,
    TECH.vscode,
    TECH.intellij_idea,
    TECH.vstd,
  ] as const,
  body: (
    <>
      I'm a passionate data engineer and a seasoned expert in Python, Rust and Scala with a strong focus
      on Backend & DevOps. I thrive on writing functional codes that not only elevates functionality but also enhances
      performance, whether it's crafting highly performant websites, automating intricate API
      integrations, building bespoke dev-tools, or creating captivating user experiences that
      evoke a sense of{" "}
      <em
        className="relative cursor-pointer before:absolute b:bottom-0 b:-z-10 b:h-3 b:w-full b:-rotate-2 b:animate-hint-hint b:bg-pink-400/70 b:blur-sm d:b:bg-pink-600"
        onClick={(e) => {
          e.currentTarget.classList.remove("before:absolute");
          party.confetti(e.currentTarget, { count: 40 });
        }}
      >
        WOW!
      </em>
      <span className="mt-4 block" />
      Presently, I'm delving into{" "}
      <Link target="_blank" href="https://zio.dev/" className="underline hfa:text-sky-500">
        Zio
      </Link>{" "}
      ,{" "}
      <Link href="https://tokio.rs/" target="_blank" className="underline hfa:text-sky-500">
        Tokio
      </Link>{" "}
      and{" "}
      <Link href="https://fastapi.tiangolo.com/" target="_blank" className="underline hfa:text-sky-500">
        FastAPI
      </Link>
      .
    </>
  ),
  cta1: {
    href: "mailto:i@mattumm.al",
    name: "Connect",
  },
  cta2: {
    href: "/resume",
    name: "Résumé",
  },
  code: `${heroCode}`,
};
